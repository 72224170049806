@font-face {
  font-family: 'MonsalGothic';
  src: url('./../public/fonts/MonsalGothic-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MonsalGothic';
  src: url('./../public/fonts/MonsalGothic-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'MonsalGothic';
  src: url('./../public/fonts/MonsalGothic-ExtraBold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'MonsalGothic';
  src: url('./../public/fonts/MonsalGothic-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'MonsalGothic';
  src: url('./../public/fonts/MonsalGothic-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}






body {
  margin: 0;
  font-family:'MonsalGothic', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'MonsalGothic', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Custom Volume Slider */
.volume-slider {
  -webkit-appearance: none; /* Remove default browser styles */
  appearance: none;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  outline: none;
  transition: background 0.3s ease-in-out;
}

/* Custom Volume Slider */
.volume-slider {
  -webkit-appearance: none; /* Remove default styles */
  appearance: none;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  outline: none;
  transition: background 0.3s ease-in-out;
}

/* WebKit (Chrome, Safari, Edge) */
.volume-slider::-webkit-slider-runnable-track {
  height: 6px;
  border-radius: 5px;
  background: transparent; /* Allow dynamic inline gradient */
}

.volume-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #12372A; /* Thumb color */
  cursor: pointer;
  position: relative;
  margin-top: -4px;
}

/* Firefox */
.volume-slider::-moz-range-track {
  height: 6px;
  border-radius: 5px;
  background: #C2F7C9;
}

.volume-slider::-moz-range-progress {
  background: #12372A;
}



/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
